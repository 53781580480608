import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "tab-customization"
    }}>{`Tab customization`}</h1>
    <blockquote>
      <p parentName="blockquote">{`These settings need to be there when the script is run, chat client doesn't currently support adhoc changing of properties`}</p>
    </blockquote>
    <h2 {...{
      "id": "change-location-of-tab"
    }}>{`Change location of tab`}</h2>
    <p>{`You can change the location of chat tab by giving it property `}<inlineCode parentName="p">{`tabLocation`}</inlineCode>{` at runtime, supported options are `}<em parentName="p">{`right`}</em>{`, `}<em parentName="p">{`bottom-right`}</em>{`, `}<em parentName="p">{`bottom-left`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    tabLocation: 'bottom-right'
  });
`}</code></pre>
    <h2 {...{
      "id": "change-locale-of-tab-and-chat"
    }}>{`Change locale of tab and chat`}</h2>
    <p>{`You can set the locale at runtime by giving the script `}<inlineCode parentName="p">{`locale`}</inlineCode>{` property at runtime. Supported locales are `}<em parentName="p">{`eng`}</em>{`, `}<em parentName="p">{`fin`}</em>{`, `}<em parentName="p">{`swe`}</em></p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    locale: 'eng'
  });
`}</code></pre>
    <h2 {...{
      "id": "remove-chat-tab-from-site"
    }}>{`Remove chat tab from site`}</h2>
    <p>{`You can keep running the chat without showing the tab on some sites, this makes it possible to run the chat in a site where you don't want customers starting new chats. Just add the propery `}<inlineCode parentName="p">{`noTab`}</inlineCode>{` to script at runtime`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    noTab: true'
  });
`}</code></pre>
    <h2 {...{
      "id": "enable-different-tab-for-contact-form"
    }}>{`Enable different tab for contact form`}</h2>
    <p>{`By setting the boolean `}<inlineCode parentName="p">{`useOfflineButton`}</inlineCode>{` at runtime you can have different tab for contact form when no agents are available.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`  Cobrowse.create({
    apiKey: "LQt/yocAfcWRAt...",
    noTab: true'
  });
`}</code></pre>
    <h2 {...{
      "id": "change-look-of-tab-on-different-sites"
    }}>{`Change look of tab on different sites`}</h2>
    <p>{`By setting properties below you can customize the colors, text and icon of the tabs shown.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Note the way button icon is added at `}<inlineCode parentName="p">{`startButtonSymbol`}</inlineCode>{` or `}<inlineCode parentName="p">{`offlineButtonSymbol`}</inlineCode>{`.`}</p>
      <p parentName="blockquote">{`You can use any `}<a parentName="p" {...{
          "href": "https://fontawesome.com/"
        }}>{`fontawesome 5`}</a>{` icon with minor changes to classnames`}</p>
    </blockquote>
    <p>{`kom-fontawb - brand icons `}<br />{`
kom-fontawl - light icons `}<br />{`
kom-fontaws - solid icons `}<br />{`
kom-fontawd - duotone icons `}<br />{`
kom-fontawr - regular icons`}</p>
    <blockquote>
      <p parentName="blockquote">{`startButtonTitle: 'LIVE CHAT FOR YOU'`}</p>
      <p parentName="blockquote">{`startButtonSymbol: `}<inlineCode parentName="p">{`'<i class="kom-fontaw kom-fontawr kom-fontaw-envelope"></i>'`}</inlineCode></p>
      <p parentName="blockquote">{`tabTextColor: '#FFFFFF'`}</p>
      <p parentName="blockquote">{`tabColor: '#000000'`}</p>
    </blockquote>
    <blockquote>
      <p parentName="blockquote">{`offlineButtonTitle: 'Offline'`}</p>
      <p parentName="blockquote">{`offlineButtonSymbol: `}<inlineCode parentName="p">{`'<i class="kom-fontaw kom-fontawr kom-fontaw-edge"></i>'`}</inlineCode></p>
      <p parentName="blockquote">{`offlineButtonTextColor: '#FF0011'`}</p>
      <p parentName="blockquote">{`offlineButtonTabColor: '#010101'`}</p>
    </blockquote>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      